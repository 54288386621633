import ApiAdmin from "@/pages/services/ApiAdmin.js";
import Settings from "@/../settings.json";
// axios
export default {
  getUserInfo(obj) {
    return ApiAdmin().get(
      `admin/getUserInfo?nickName=${obj.nickName}&areaName=${obj.areaName}`
    );
  },
  getListUser(obj) {
    return ApiAdmin().get(
      `admin/getListUser?nickName=${obj.nickName}&areaName=${obj.areaName}`
    );
  },
  getInfoAdminWallet(obj) {
    return ApiAdmin().post("/admin/get-info-admin-wallet",obj);
  },
  updateInfoAdminWallet(obj) {
    return ApiAdmin().post("/admin/update-info-admin-wallet",obj);
  },
  checkProfit(obj) {
    return ApiAdmin().get(`admin/checkProfit?nickName=${obj.nickName}&fromTime=${obj.fromTime}&toTime=${obj.toTime}`);
  },
  createCommission(obj) {
    return ApiAdmin().post("/admin/createCommission",obj);
  },
  addMoneyAdmin(obj) {
    return ApiAdmin().post("admin/addMoneyAdmin", obj);
  },
  removeMoneyAdmin(obj) {
    return ApiAdmin().post("admin/removeMoneyAdmin",obj);
  },
  searchBetAdmin(obj) {
    return ApiAdmin().get(`admin/searchBetAdmin?nickName=${obj.nickName}&areaName=${obj.areaName}`);
  },
  betAdmin(obj) {
    return ApiAdmin().post("admin/betAdmin", obj);
  },
  setInfoAdmin(obj) {
    return ApiAdmin().post("/admin/set-info-admin", obj);
  },

  getStaticsArea(obj) {
    return ApiAdmin().post("/admin/getStaticsArea", obj);
  },

  createAdmin(obj) {
    return ApiAdmin().post("/admin/create-admin", obj);
  },

  getListAdd() {
    return ApiAdmin().get("/admin/ListAddMoney");
  },
  getAdminInfo(obj) {
    return ApiAdmin().get(
      `admin/getAdminInfo?username=${obj.username}&areaName=${obj.areaName}`
    );
  },

  updateBreakBetsConfig(obj) {
    return ApiAdmin().post("/admin/update-break-bets-config", obj);
  },

  getBreakBetsConfig() {
    return ApiAdmin().get("/admin/break-bets-config");
  },

  getListWithdraw(obj) {
    return ApiAdmin().get(`/admin/getListWithdraw?areaName=${obj.areaName}&type=${obj.type}`);
  },

  getListWithdrawNick(obj) {
    return ApiAdmin().get(`/admin/getListWithdrawNick?areaName=${obj.areaName}&nickName=${obj.nickName}&type=${obj.type}`);
  },

  getListDeposit(obj) {
    return ApiAdmin().get(`/admin/getListDeposit?areaName=${obj.areaName}`);
  },

  getListDepositNick(obj) {
    return ApiAdmin().get(
      `/admin/getListDepositNick?areaName=${obj.areaName}&nickName=${obj.nickName}`
    );
  },
  CreateNotify(obj) {
    return ApiAdmin().post("/admin/CreateNotify",obj);
  },
  getListTrade(obj) {
    return ApiAdmin().post("/admin/getListTrade",obj);
  },
  getListTradeNick(obj) {
    return ApiAdmin().get(
      `/admin/getListTradeNick?nickName=${obj.nickName}&areaName=${obj.areaName}`
    );
  },
  getListWithdrawPending() {
    return ApiAdmin().get("/admin/getListWithdrawPending");
  },

  confirmWithdraw(obj) {
    return ApiAdmin().post("/admin/confirm-withdraw", obj);
  },

  getInfoTradingCommissionRanksByTime(obj) {
    return ApiAdmin().post("/admin/get-tradingcoms-ranks", obj);
  },

  getListVerifing(areaName) {
    return ApiAdmin().get(`/admin/getListVerify/${areaName}`);
  },

  confirmKYCAccount(obj) {
    return ApiAdmin().post("/admin/confirm-kyc", obj);
  },

  getInfoVolumeRanksByTime(obj) {
    return ApiAdmin().post("/admin/get-volume-ranks", obj);
  },

  sendBalanceMarketing(obj) {
    return ApiAdmin().post("/admin/send-balance-marketing", obj);
  },

  getRevenueNap() {
    return ApiAdmin().get("api/trades/getRevenueNap");
  },

  getRevenueRut() {
    return ApiAdmin().get("api/trades/getRevenueRut");
  },

  getRevenueTrans() {
    return ApiAdmin().get("api/trades/getRevenueTrans");
  },

  getShowDT(obj) {
    return ApiAdmin().post("api/trades/getShowDT", obj);
  },

  changeAccMarketing(obj) {
    return ApiAdmin().post("api/users/changeAcc", obj);
  },

  changePassAdmin(obj) {
    return ApiAdmin().post("api/users/changPassAd", obj);
  },

  createUser(obj) {
    return ApiAdmin().post("api/users/create", obj);
  },

  register(obj) {
    return ApiAdmin().post("api/users/register", obj);
  },

  loginAdmin(obj) {
    return ApiAdmin().post("admin/auth/login", obj);
  },

  checkEmail(email) {
    return ApiAdmin().get("api/users/checkEmail/" + email);
  },

  getAllMember(obj) {
    return ApiAdmin().get(
      `admin/getUserInfo?nickName=${obj.nickName}&areaName=${obj.areaName}`
    );
  },
  updateUserInfo(obj) {
    return ApiAdmin().post("admin/update-user", obj);
  },

  updateMember(obj) {
    return ApiAdmin().patch("api/users/updateUser", obj);
  },

  updatePriceMember(obj) {
    return ApiAdmin().patch("api/users/updateMoney", obj);
  },

  deleteMember(id) {
    return ApiAdmin().delete("api/users/deleteUserById/" + id);
  },

  verifiedUser(obj) {
    return ApiAdmin().post("api/users/verifiedUser", obj);
  },

  turnOff2FA(obj) {
    return ApiAdmin().post("api/users/turn-off-gg2fa", obj);
  },

  activeUser(obj) {
    return ApiAdmin().post("api/users/activeUserAdmin", obj);
  },

  activeAccount(obj) {
    return ApiAdmin().post("api/users/activeAccount", obj);
  },

  getListAgency() {
    return ApiAdmin().get("api/users/getAgency");
  },

  viewMemberAgency(id) {
    return ApiAdmin().get("api/users/viewTotalMAgency/" + id);
  },

  
  getRateCommission() {
    return ApiAdmin().get("api/setup/getRateCommission");
  },

  saveRateCommission(obj) {
    return ApiAdmin().post("api/setup/saveRateCommission", obj);
  },

  saveRateCommission(obj) {
    return ApiAdmin().post("api/setup/saveRateCommission", obj);
  },

  getInforAccount(email) {
    return ApiAdmin().get("api/users/getInforAccount/" + email);
  },

  createAdmin2FA() {
    return ApiAdmin().get("api/users/create-admin-gg2fa");
  },

  activeAdmin2FA(obj) {
    return ApiAdmin().post("api/users/update-admin-gg2fa", obj);
  },

  unActiveAdmin2FA(obj) {
    return ApiAdmin().post("api/users/disable-admin-gg2fa", obj);
  },

  //================
  //================
  //================
  //================
  //========== TRADE
  //================
  //================
  //================

  getAddMoneyListHistory() {
    return ApiAdmin().get("api/trades/historyAllAddMoney");
  },

  getTotalAddMoney() {
    return ApiAdmin().get("api/trades/totalAddMoney");
  },

  getTradeListHistory() {
    return ApiAdmin().get("api/trades/historyAll");
  },

  gethistoryAllTrash() {
    return ApiAdmin().get("api/trades/historyAllTrash");
  },

  deleteTrashByID(obj) {
    return ApiAdmin().patch("api/trades/deleteTradeHisById", obj);
  },

  getDepositListHistory() {
    return ApiAdmin().get("api/trades/hisDepositAll");
  },

  getDepositAllTrash() {
    return ApiAdmin().get("api/trades/hisDepositAllTrash");
  },

  getWithdrawalListHistory() {
    return ApiAdmin().get("api/trades/hisWithDrawalAll");
  },

  doneWithDrawalByID(obj) {
    return ApiAdmin().post("api/trades/doneWithdrawal", obj);
  },

  doneRefuseWithDrawalByID(obj) {
    return ApiAdmin().post("api/trades/doneRefuseWithdrawal", obj);
  },

  getListF1F7(obj) {
    return ApiAdmin().post("api/users/getListF1F7", obj);
  },

  getLisCommissionSearch(obj) {
    return ApiAdmin().post("api/users/getListCmsHis", obj);
  },

  getTongGiaoDichCapDuoi(params) {
    return ApiAdmin().get("api/users/bet-total-down-line", { params });
  },

  //================
  //================
  //================
  //================
  //================

  //================
  //================
  //================
  //========== Analytics
  //================
  //================
  //================

  getAnalytics() {
    return ApiAdmin().get("api/users/analytics");
  },

  //================
  //================
  //================
  //========== Analytics
  //================
  //================
  //================

  //================
  //================
  //================
  //========== BET
  //================
  //================
  //================

  getBetsListHistory() {
    return ApiAdmin().get("api/bets/historyBet");
  },

  getBetsListHisTrash() {
    return ApiAdmin().get("api/bets/hisBetTrash");
  },

  deleteBetsTrash(obj) {
    return ApiAdmin().patch("api/bets/deleteBet", obj);
  },

  //================
  //================
  //================
  //================
  //================

  //================
  //================
  //================
  //========== EXCHANGE
  //================
  //================
  //================

  getExListHistory() {
    return ApiAdmin().get("api/exs/historyEx");
  },

  getExListHisTrash() {
    return ApiAdmin().get("api/exs/historyExTrash");
  },

  deleteExTrash(obj) {
    return ApiAdmin().patch("api/exs/deleteEx", obj);
  },

  //================
  //================
  //================
  //================
  //================

  uploadAvatar(obj) {
    return Api().post("user/changeAvatar", obj);
  },

  uploadPassportFront(obj) {
    return Api().post("user/cidFront", obj);
  },

  uploadPassportBack(obj) {
    return Api().post("user/cidBack", obj);
  },

  refreshAdminToken(obj) {
    return ApiAdmin().post("admin/auth/refreshToken", obj);
  },
};
